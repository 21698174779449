document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('nav-tab');
  const coupon = document.querySelector('.coupon');
  const couponGroups = document.querySelector('.coupon_groups');

  if (couponGroups != null) {
    couponGroups.classList.add('hidden');
  }

  function onTabClick(event) {
    const activeTabs = document.querySelectorAll('.active');

    activeTabs.forEach(function(tab) {
      tab.classList.toggle('active');
    });
    // activate new tab and panel
    event.target.parentElement.classList.add('active');
    event.preventDefault();
  }

  function determineType(event) {

    const types = {
      coupon: "coupon",
      coupon_groups: "coupon_groups",
    };

    // Coupon
    if (event.target.parentElement.dataset.tab == types.coupon) {
      coupon.classList.remove('hidden');
      couponGroups.classList.add('hidden');

      couponGroups.querySelector("select").value = "";
    }

    // Coupon Groups
    if (event.target.parentElement.dataset.tab == types.coupon_groups) {
      couponGroups.classList.remove('hidden');
      coupon.classList.add('hidden');

      coupon.querySelector("input[type=text]").value = "";
    }

  }

    if (element != null) {
    element.addEventListener('click', function(event) {
      onTabClick(event);
      determineType(event);
    });
  }
});